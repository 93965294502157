import { UpgradeMembershipComponent } from './../upgrade-membership/upgrade-membership.component';
import { filter, map, mergeMap } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { _modules } from 'src/app/shared/model/model';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
  standalone: false,
})
export class DashboardLayoutComponent {
  modules = _modules;

  showFiller = false;
  data: any;
  url: string = environment.api;
  picture: any;
  currentModule: string = 'celpip';

  constructor(
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public title: Title,
    public matDialog: MatDialog
  ) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => route),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((data: any) => {
        this.data = data;
        title.setTitle(data.title);
      });
    this.currentModule = this.getModule();
    this.modules = _modules.filter(
      (module) => module.name != this.currentModule
    );
  }

  opening: boolean = false;

  onClick() {
    if (this.mobileAndTabletCheck()) {
      if (!this.opening) {
        document.body.classList.toggle('sb-sidenav-toggled');
      }
      this.opening = false;
    }
  }

  sideNavToggler(e: any, type: string = '') {
    if (type == 'toggler') {
      this.opening = true;
      // Uncomment Below to persist sidebar toggle between refreshes
      // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
      //     document.body.classList.toggle('sb-sidenav-toggled');
      // }
      e.preventDefault();
      document.body.classList.toggle('sb-sidenav-toggled');
      const _class: any = document.body.classList;
      localStorage.setItem(
        'sb|sidebar-toggle',
        _class.contains('sb-sidenav-toggled')
      );
    }
  }

  upgradeMembership() {
    this.matDialog.open(UpgradeMembershipComponent, {
      panelClass: 'mat-dialog-sm',
      minWidth: '300px',
    });
  }

  mobileAndTabletCheck(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Common regex pattern for mobile and tablet detection
    const mobileRegex = /android|blackberry|iphone|ipad|ipod|opera mini|iemobile|windows phone|kindle|silk|mobile|playbook/i;

    return mobileRegex.test(userAgent);
  }

  changeModule(module: string): void {
    localStorage.setItem('module', module);
    this.auth.changeModule();
    this.setModule();
    this.auth.moduleChanged.subscribe({
      next: (res) => {
        if (res) {
          const currentUrl = this.router.url;
          if (currentUrl === '/') {
            window.location.reload();
          } else {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate([currentUrl]);
            });
          }
        }
      },
    });
  }

  getModule(): string {
    return localStorage.getItem('module') || 'celpip';
  }

  setModule(): void {
    this.currentModule = this.getModule();
    this.modules = _modules.filter(
      (module) => module.name != this.currentModule
    );
  }
}
